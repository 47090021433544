import { ElMessage, ElLoading, ElMessageBox } from 'element-plus'


const eleui = {
    alert: (msg, msgtype = 'error', duration = 3) => ElMessage({
        showClose: true,
        center: true,
        offset: 50,
        message: decodeURIComponent(msg),
        type: msgtype,
        duration: duration * 1000,
        customClass: 'messagezindex',
        dangerouslyUseHTMLString: true
    }),
    loading: (text) => {
        if (text) {
            return ElLoading.service({
                lock: true,
                text: text,
                spinner: '<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32zm448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32zm-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32zM195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0zm-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"></path></svg>',
                background: 'rgba(0, 0, 0, 0.3)',
                customClass: 'loadingtop'
            });
        }
        else {
            return '';
        }
    },
    loadingClose: (obj) => {
        if (obj) {
            obj.close();
        }
    },
    confirm: (msg, callbackyes, callbackno, title = '提示', btnyes = '确定', btnno = '取消') => {
        ElMessageBox.confirm(msg, title, {
            confirmButtonText: btnyes,
            cancelButtonText: btnno,
            type: 'warning'
        }).then(() => {
            callbackyes();
        }).catch(() => {
            if (callbackno != undefined) {
                callbackno();
            }
        });
    }
}

export default eleui
