import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7b81c942"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "swiperwrap"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "swiperbg"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = ["src"];
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  key: 0,
  href: "item.url"
};
const _hoisted_9 = ["src"];
const _hoisted_10 = ["innerHTML"];
const _hoisted_11 = ["src"];
const _hoisted_12 = ["innerHTML"];
const _hoisted_13 = {
  key: 0,
  class: "ffswiperpoint"
};
const _hoisted_14 = ["onClick"];
export default {
  __name: 'ffswiper',
  props: {
    imgs: {
      type: String,
      default: ''
    },
    infoList: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    },
    indexCur: {
      type: Number,
      default: 0
    }
  },
  setup(__props, {
    expose: __expose
  }) {
    const FFprops = __props;
    const {
      proxy
    } = getCurrentInstance();

    //const emit = defineEmits(['update:indexCur', 'update:direct'])

    let LBInterval;
    let optionsDefault = {
      'isautoplay': true,
      'isstop': false,
      'duration': 3000,
      'ispoint': true,
      'isfull': false,
      'istrandriect': false
    }; //isfull是内容格式时是否有padding

    const FFdata = reactive({
      direct: 1,
      myIndex: 0,
      //组件内索引
      myStop: false //组件内停止
    });

    const Coptions = computed(() => Object.assign({}, optionsDefault, FFprops.options));
    const Cimgs = computed(() => FFprops.imgs ? FFprops.imgs.split(',') : []);
    const CisImg = computed(() => Cimgs.value.length > 0);
    const CitemMax = computed(() => CisImg.value ? Cimgs.value.length : FFprops.infoList.length);
    const CtranName = computed(() => FFdata.direct == 1 ? 'ffswiperleft' : 'ffswiperright');
    const CisStop = computed(() => FFdata.myStop || Coptions.value.isstop);
    const updateIndexCur = (v, direct) => {
      if (CitemMax.value > 1) {
        FFdata.myIndex = v >= CitemMax.value ? 0 : v < 0 ? CitemMax.value - 1 : v;
        if (Coptions.value.istrandriect && direct && direct != FFprops.direct) {
          FFdata.direct = direct;
        }
      }
    };
    const autoPlay = () => {
      if (Coptions.value.isautoplay) {
        LBInterval = window.setInterval(() => {
          if (!CisStop.value) {
            FFdata.direct == 1 ? swiperNext() : swiperPrev();
          }
        }, Coptions.value.duration);
      }
    };
    const touchSwiper = e => {
      if (e.direction == "Left") {
        swiperNext();
      } else if (e.direction == "Right") {
        swiperPrev();
      }
    };
    const swiperGo = v => {
      let direct = v > FFdata.myIndex ? 1 : 2;
      updateIndexCur(v, direct);
    };
    const swiperPrev = () => {
      let v = FFdata.myIndex - 1;
      swiperGo(v);
    };
    const swiperNext = () => {
      let v = FFdata.myIndex + 1;
      swiperGo(v);
    };
    const swiperOver = () => {
      FFdata.myStop = true;
    };
    const swiperOut = () => {
      FFdata.myStop = false;
    };
    onMounted(() => {
      FFdata.myIndex = FFprops.indexCur;
      autoPlay();
    });
    onUnmounted(() => {
      window.clearInterval(LBInterval);
    });
    __expose({
      swiperPrev,
      swiperNext,
      swiperGo
    });
    return (_ctx, _cache) => {
      const _directive_finger = _resolveDirective("finger");
      return _unref(CitemMax) == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
        src: FFprops.imgs,
        class: "ffbannerimg"
      }, null, 8, _hoisted_2)])) : _unref(CitemMax) ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "swiperwrap",
        onMouseover: swiperOver,
        onMouseout: swiperOut
      }, [_createElementVNode("ul", _hoisted_3, [_unref(CisImg) ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 0
      }, _renderList(_unref(Cimgs), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("img", {
          src: item,
          class: "swiperimg"
        }, null, 8, _hoisted_4)]);
      }), 128)) : (_openBlock(true), _createElementBlock(_Fragment, {
        key: 1
      }, _renderList(__props.infoList, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [item.img ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: item.img,
          class: "swiperimg"
        }, null, 8, _hoisted_5)) : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([_unref(Coptions).isfull ? 'editor2' : 'editor'])
        }, [_createElementVNode("span", {
          innerHTML: _ctx.$common.langinfo(item.content, item.content_en)
        }, null, 8, _hoisted_6)], 2))]);
      }), 128))]), _createVNode(_TransitionGroup, {
        name: _unref(CtranName),
        tag: "ul",
        class: "swiperbox"
      }, {
        default: _withCtx(() => [_unref(CisImg) ? (_openBlock(true), _createElementBlock(_Fragment, {
          key: 0
        }, _renderList(_unref(Cimgs), (item, index) => {
          return _withDirectives((_openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("img", {
            src: item,
            class: "swiperimg"
          }, null, 8, _hoisted_7)])), [[_vShow, index == _unref(FFdata).myIndex]]);
        }), 128)) : (_openBlock(true), _createElementBlock(_Fragment, {
          key: 1
        }, _renderList(__props.infoList, (item, index) => {
          return _withDirectives((_openBlock(), _createElementBlock("li", {
            key: index
          }, [item.url ? (_openBlock(), _createElementBlock("a", _hoisted_8, [item.img ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: item.img,
            class: "swiperimg"
          }, null, 8, _hoisted_9)) : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass([_unref(Coptions).isfull ? 'editor2' : 'editor'])
          }, [_createElementVNode("span", {
            innerHTML: _ctx.$common.langinfo(item.content, item.content_en)
          }, null, 8, _hoisted_10)], 2))])) : (_openBlock(), _createElementBlock(_Fragment, {
            key: 1
          }, [item.img ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: item.img,
            class: "swiperimg"
          }, null, 8, _hoisted_11)) : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass([_unref(Coptions).isfull ? 'editor2' : 'editor'])
          }, [_createElementVNode("span", {
            innerHTML: _ctx.$common.langinfo(item.content, item.content_en)
          }, null, 8, _hoisted_12)], 2))], 64))])), [[_vShow, index == _unref(FFdata).myIndex]]);
        }), 128))]),
        _: 1
      }, 8, ["name"]), _unref(Coptions).ispoint && _unref(CitemMax) > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_13, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CitemMax), index => {
        return _openBlock(), _createElementBlock("a", {
          key: index,
          href: "#",
          onClick: $event => swiperGo(index - 1),
          class: _normalizeClass({
            cs: index == _unref(FFdata).myIndex + 1
          })
        }, null, 10, _hoisted_14);
      }), 128))])) : _createCommentVNode("", true)], 32)), [[_directive_finger, touchSwiper, "swipe"]]) : _createCommentVNode("", true);
    };
  }
};