import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-34e1ce09"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  href: "item.url",
  title: "$common.langInfo(item.title,item.title_en)"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  key: 0,
  href: "item.url",
  title: "$common.langInfo(item.title,item.title_en)"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  key: 0,
  href: "item.url",
  title: "$common.langInfo(item.title,item.title_en)"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = ["src"];
export default {
  __name: 'ffswiper2',
  props: {
    infoList: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    },
    direct: {
      type: Number,
      default: 1
    }
  },
  emits: ['update:indexCur', 'update:direct'],
  setup(__props, {
    emit
  }) {
    const FFprops = __props;
    const {
      proxy
    } = getCurrentInstance();
    let LBInterval;
    let optionsDefault = {
      'isautoplay': true,
      'isstop': false,
      'duration': 10,
      'step': 1,
      'istrandriect': false
    };
    const swrap = ref(null);
    const sbox = ref(null);
    const sitem = ref(null);
    const FFdata = reactive({
      moveLeft: 0,
      //移动距离
      myStop: false //组件内停止
    });

    const Coptions = computed(() => Object.assign({}, optionsDefault, FFprops.options));
    const CitemMax = computed(() => FFprops.infoList.length);
    const CisStop = computed(() => FFdata.myStop || Coptions.value.isstop);
    const autoPlay = () => {
      if (Coptions.value.isautoplay && CitemMax.value > 1) {
        LBInterval = window.setInterval(() => {
          if (!CisStop.value) {
            wsiperPlay(FFprops.direct);
          }
        }, Coptions.value.duration);
      }
    };
    const touchSwiper = e => {
      if (e.direction == "Left") {
        wsiperPlay(1);
      } else if (e.direction == "Right") {
        wsiperPlay(2);
      }
    };
    const wsiperPlay = direct => {
      let cleft = FFdata.moveLeft;
      let wrapwidth = swrap.value.clientWidth;
      let boxwidth = sbox.value.clientWidth;
      if (direct == 1 && cleft + boxwidth <= 0) {
        cleft = cleft + boxwidth;
        FFdata.moveLeft = cleft;
      } else if (direct == 2 && cleft + Coptions.value.step >= 0) {
        cleft = cleft + Coptions.value.step - boxwidth;
        FFdata.moveLeft = cleft;
      }
      nextTick(() => {
        if (direct == 1) {
          cleft -= Coptions.value.step;
        } else {
          cleft += Coptions.value.step;
        }
        FFdata.moveLeft = cleft;
        if (Coptions.value.istrandriect && direct && direct != FFprops.direct) {
          emit('update:direct', direct);
        }
      });
    };
    const swiperOver = () => {
      FFdata.myStop = true;
    };
    const swiperOut = () => {
      FFdata.myStop = false;
    };
    onMounted(() => {
      nextTick(() => {
        autoPlay();
      });
    });
    onUnmounted(() => {
      window.clearInterval(LBInterval);
    });
    return (_ctx, _cache) => {
      const _directive_finger = _resolveDirective("finger");
      return _unref(CitemMax) ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "swiper2wrap",
        onMouseover: swiperOver,
        onMouseout: swiperOut,
        ref_key: "swrap",
        ref: swrap
      }, [_createElementVNode("div", {
        class: "swiper2info",
        style: _normalizeStyle({
          left: _unref(FFdata).moveLeft + 'px'
        })
      }, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.infoList, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          class: "swiper2box"
        }, [item.url ? (_openBlock(), _createElementBlock("a", _hoisted_1, [_createElementVNode("img", {
          src: item.img,
          class: "swiper2img"
        }, null, 8, _hoisted_2)])) : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: item.img,
          class: "swiper2img"
        }, null, 8, _hoisted_3))]);
      }), 128))]), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.infoList, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          class: "swiper2box"
        }, [item.url ? (_openBlock(), _createElementBlock("a", _hoisted_4, [_createElementVNode("img", {
          src: item.img,
          class: "swiper2img"
        }, null, 8, _hoisted_5)])) : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: item.img,
          class: "swiper2img"
        }, null, 8, _hoisted_6))]);
      }), 128))])], 4), _createElementVNode("ul", {
        class: "swiper2bg",
        ref_key: "sbox",
        ref: sbox
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.infoList, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          class: "swiper2box",
          ref_for: true,
          ref_key: "sitem",
          ref: sitem
        }, [item.url ? (_openBlock(), _createElementBlock("a", _hoisted_7, [_createElementVNode("img", {
          src: item.img,
          class: "swiper2img"
        }, null, 8, _hoisted_8)])) : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: item.img,
          class: "swiper2img"
        }, null, 8, _hoisted_9))]);
      }), 128))], 512)], 32)), [[_directive_finger, touchSwiper, "swipe"]]) : _createCommentVNode("", true);
    };
  }
};