import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["href", "title"];
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
export default {
  __name: 'fflink',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    img: {
      type: String,
      default: ''
    },
    root: {
      type: String,
      default: ''
    },
    fn: {
      type: String,
      default: 'id'
    },
    title: {
      type: String,
      default: ''
    },
    isOverDot: {
      type: Boolean,
      default: true
    }
  },
  setup(__props) {
    const FFprops = __props;
    const {
      proxy
    } = getCurrentInstance();
    const Cinfo = computed(() => {
      let _info = {};
      if (proxy.$common.basic.isObjExist(FFprops.info)) {
        _info = FFprops.info;
        _info.x_url = FFprops.root + '/' + _info[FFprops.fn];
      }
      return _info;
    });
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return _unref(Cinfo).url ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _unref(Cinfo).url,
        target: "_blank",
        class: _normalizeClass(['fflink', __props.isOverDot ? 'overdot' : '']),
        title: __props.title || _ctx.$common.langInfo(_unref(Cinfo).title, _unref(Cinfo).title_en)
      }, [__props.img || _unref(Cinfo).img || _unref(Cinfo).cover ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: __props.img || _unref(Cinfo).img || _unref(Cinfo).cover
      }, null, 8, _hoisted_2)) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createTextVNode(_toDisplayString(__props.title || _ctx.$common.langInfo(_unref(Cinfo).title, _unref(Cinfo).title_en)), 1)], 64))], 10, _hoisted_1)) : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        to: _unref(Cinfo).x_url,
        class: _normalizeClass(['fflink', __props.isOverDot ? 'overdot' : '']),
        title: __props.title || _ctx.$common.langInfo(_unref(Cinfo).title, _unref(Cinfo).title_en)
      }, {
        default: _withCtx(() => [__props.img || _unref(Cinfo).img || _unref(Cinfo).cover ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: __props.img || _unref(Cinfo).img || _unref(Cinfo).cover
        }, null, 8, _hoisted_3)) : (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_createTextVNode(_toDisplayString(__props.title || _ctx.$common.langInfo(_unref(Cinfo).title, _unref(Cinfo).title_en)), 1)], 64))]),
        _: 1
      }, 8, ["to", "class", "title"]));
    };
  }
};