import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["src"];
export default {
  __name: 'ffimg',
  props: {
    alias: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const FFprops = __props;
    const {
      proxy
    } = getCurrentInstance();
    const FFdata = reactive({
      imgurl: ''
    });
    onMounted(() => {
      if (FFprops.src) {
        FFdata.imgurl = FFprops.src;
      } else if (FFprops.alias) {
        proxy.$common.fetch({
          'act': 'common_infoimg',
          'exts': 'alias=' + FFprops.alias,
          'msg': ''
        }, res => {
          FFdata.imgurl = res.infodata ? res.infodata.imgs : '';
        });
      }
    });
    return (_ctx, _cache) => {
      return _unref(FFdata).imgurl ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _unref(FFdata).imgurl
      }, null, 8, _hoisted_1)) : _createCommentVNode("", true);
    };
  }
};