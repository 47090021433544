import basic from '@/common/basic.js'
import json from '@/common/json.js'
import Cookie from 'vue-cookies';
import { defineStore } from 'pinia'

export const useSettingStore = defineStore('setting', {
    // 为了完整类型推理，推荐使用箭头函数
    state: () => {
        return {
            // 所有这些属性都将自动推断出它们的类型
            isLogin: basic.chStr2Int(Cookie.get('isLogin')),
            langCur: basic.chStr2Int(Cookie.get('lang'), 1),
            langDic: json.sync('/include/dic2.json'),
            config: json.sync('/config.json'),
            pngPre: 'data:image/png;base64,',
            wh: document.body.clientHeight,// window.innerHeight,// document.body.clientHeight;
            ww: document.body.clientWidth,//window.innerWidth,// document.body.clientWidth;
            navKv: 1,
            navIdSub: 0,
            navList: []
        }
    },
    actions: {
        SET_ISLOGIN(v) {
            if (v != this.isLogin) {
                this.isLogin = v;
                Cookie.set('isLogin', v, -1);
            }
        },
        SET_LANGCUR(v) {
            let _lang = 0;
            if (v) {
                _lang = v;
            }
            else {
                let clang = basic.chStr2Int(Cookie.get('lang'));
                if (clang == 0) {
                    _lang = (navigator.language || navigator.browserLanguage || navigator.userLanguage).toLowerCase() == "zh-cn" ? 1 : 2;
                }
            }
            if ((_lang > 0) && (this.langCur != _lang)) {
                this.langCur = _lang;
                Cookie.set('lang', _lang, -1);
            }

        },
        SET_NAVKV(v1, v2) {
            if (v1 != this.navKv) {
                this.navKv = v1 || 0;
            }
            if (v2 != this.navIdSub) {
                this.navIdSub = v2 || 0;
            }
        },
        SET_NAVLIST(v) {
            this.navList = v;
        },
        async setIsLogin(v) {
            this.SET_ISLOGIN(v);
        },
        async setLangCur(v) {
            this.SET_LANGCUR(v);
        },
        async setNavList(v) {
            this.SET_NAVLIST(v);
        }

    }
});
