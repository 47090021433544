const validate = {
    check: (v, options) => {
        let isvali = true;
        if (options) {
            options = typeof options == 'object' ? options : options.split(',');
            options.forEach(item => isvali = valiCheck(v, item));
        }
        return isvali ? 1 : -1;
    }
}
export default validate

let valiCheck = (v, sign) => {
    let flag = true;

    if (sign == "isrequire") {
        flag = (v != undefined) && (v != "");
    }
    else if (sign == "isidcard") {
        flag = v ? validateIdcard(v) : true;
    }
    else {
        if (v) {
            let re = "";

            switch (sign) {
                case "isnum":
                    re = /^(-?\d+)(\.\d+)?$/;
                    break;
                case "isint":
                    re = /^(-?\d+)?$/;
                    break;
                case "isip":
                    re = /^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9]{1,2})(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9]{1,2})){3}$/;
                    break;
                case "isemail":
                    re = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
                    break;
                case "ismobile":
                    re = /^1[3456789]\d{9}$/;
                    break;
                case "isurl":
                    re = /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

                    break;
                case "isdate":
                    re = /^((((1[6-9]|[2-9]\d)\d{2})-(0?[13578]|1[02])-(0?[1-9]|[12]\d|3[01]))|(((1[6-9]|[2-9]\d)\d{2})-(0?[13456789]|1[012])-(0?[1-9]|[12]\d|30))|(((1[6-9]|[2-9]\d)\d{2})-0?2-(0?[1-9]|1\d|2[0-9]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))-0?2-29-)) +(\d{1}|0\d{1}|1\d{1}|2[0-3]):(([0-5]\d{1}):([0-5]\d{1}))|(([0-5]\d{1}))$/;
                    break;
                case "isdatetime":
                    re = /^((((1[6-9]|[2-9]\d)\d{2})-(0?[13578]|1[02])-(0?[1-9]|[12]\d|3[01]))|(((1[6-9]|[2-9]\d)\d{2})-(0?[13456789]|1[012])-(0?[1-9]|[12]\d|30))|(((1[6-9]|[2-9]\d)\d{2})-0?2-(0?[1-9]|1\d|2[0-9]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))-0?2-29-)) +(\d{1}|0\d{1}|1\d{1}|2[0-3]):(([0-5]\d{1}):([0-5]\d{1}))|(([0-5]\d{1}))$/;
                    break;
                case "istime":
                    re = /^(\d{1}|0\d{1}|1\d{1}|2[0-3]):(([0-5]\d{1}):([0-5]\d{1}))|(([0-5]\d{1}))$/;
                    break;
                case "isnospecial":
                    re = /^[a-zA-Z0-9\u4E00-\u9FA5_]+$/;
                    break;
            }
            if (re != "") {
                flag = (re.test(v));
            }
        }
    }
    return flag
};

let validateIdcard = (v) => {
    var idNum = v;
    var re;
    var len = idNum.length;
    //身份证位数检验
    if (len != 15 && len != 18) {
        return false;
        //return errors[1];
    } else if (len == 15) {
        re = new RegExp(/^(\d{6})()?(\d{2})(\d{2})(\d{2})(\d{3})$/);
    } else {
        re = new RegExp(/^(\d{6})()?(\d{4})(\d{2})(\d{2})(\d{3})([0-9xX])$/);
    }

    var area = {
        11: "北京", 12: "天津", 13: "河北", 14: "山西",
        15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江", 31: "上海",
        32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西",
        37: "山东", 41: "河南", 42: "湖北", 43: "湖南", 44: "广东",
        45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州",
        53: "云南", 54: "西藏", 61: "陕西", 62: "甘肃", 63: "青海",
        64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门",
        91: "国外"
    }

    var idcard_array = new Array();
    idcard_array = idNum.split("");

    //地区检验
    if (area[parseInt(idNum.substr(0, 2))] == null) {
        return false;
        //return errors[4];
    }

    //出生日期正确性检验
    var a = idNum.match(re);

    if (a != null) {
        let flag = false;
        let DD = '';
        if (len == 15) {
            DD = new Date("19" + a[3] + "/" + a[4] + "/" + a[5]);
            flag = DD.getYear() == a[3] && (DD.getMonth() + 1) == a[4] && DD.getDate() == a[5];
        }
        else if (len == 18) {
            DD = new Date(a[3] + "/" + a[4] + "/" + a[5]);
            flag = DD.getFullYear() == a[3] && (DD.getMonth() + 1) == a[4] && DD.getDate() == a[5];
        }
        if (!flag) {
            return false;
            //return "身份证出生日期不对！";
        }
        //检验校验位
        if (len == 18) {
            let S = (parseInt(idcard_array[0]) + parseInt(idcard_array[10])) * 7
                + (parseInt(idcard_array[1]) + parseInt(idcard_array[11])) * 9
                + (parseInt(idcard_array[2]) + parseInt(idcard_array[12])) * 10
                + (parseInt(idcard_array[3]) + parseInt(idcard_array[13])) * 5
                + (parseInt(idcard_array[4]) + parseInt(idcard_array[14])) * 8
                + (parseInt(idcard_array[5]) + parseInt(idcard_array[15])) * 4
                + (parseInt(idcard_array[6]) + parseInt(idcard_array[16])) * 2
                + parseInt(idcard_array[7]) * 1
                + parseInt(idcard_array[8]) * 6
                + parseInt(idcard_array[9]) * 3;

            let Y = S % 11;
            let M = "F";
            let JYM = "10X98765432";
            M = JYM.substr(Y, 1); //判断校验位

            //检测ID的校验位
            if (M == idcard_array[17]) {
                return true;
                //return ""; 
            }
            else {
                return false;
                //return errors[3];
            }
        }

    } else {
        return false;
        //return errors[2];
    }
    return true;
};
