import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["innerHTML"];
export default {
  __name: 'fftitle',
  props: {
    alias: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const FFprops = __props;
    const {
      proxy
    } = getCurrentInstance();
    const FFdata = reactive({
      infodata: {},
      isLoad: false
    });
    onMounted(() => {
      if (FFprops.alias) {
        proxy.$common.fetch({
          'act': 'common_infotitle',
          'exts': 'alias=' + FFprops.alias,
          'msg': ''
        }, res => {
          if (proxy.$common.basic.isObjExist(res.infodata)) {
            FFdata.infodata = res.infodata;
          } else {
            FFdata.infodata.title = FFprops.alias;
          }
          FFdata.isLoad = true;
        });
      }
    });
    return (_ctx, _cache) => {
      return _unref(FFdata).isLoad ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: "word",
        innerHTML: _ctx.$common.langInfo(_unref(FFdata).infodata.title, _unref(FFdata).infodata.title_en)
      }, null, 8, _hoisted_1)) : _createCommentVNode("", true);
    };
  }
};