var json = {
    getPart: (fromjson, partindex) => {
        let newjson = {};
        for (var key in fromjson) {
            newjson[key] = fromjson[key][partindex];
        }
        return newjson;
    },

    sync: (url) => {
        let xhr = new XMLHttpRequest()
        xhr.open('get', url + '?r=' + Math.random(), false)
        xhr.send()
        return JSON.parse(xhr.responseText)
    }
}

export default json
