import { createApp } from 'vue';
import Ele from 'element-plus';
import { ClickOutside as vClickOutside } from 'element-plus';
import 'element-plus/dist/index.css';
import App from '@/App.vue';
import router from '@/router';
import pinia from '@/store/store.js';
import { useSettingStore } from '@/store/setting.js';
import AlloyFinger from 'alloyfinger';
import AlloyFingerPlugin from 'alloyfinger/vue/alloy_finger_vue';

import NProgress from 'nprogress'; // Progress 进度条
import 'nprogress/nprogress.css'; // Progress 进度条样式

import 'babel-polyfill';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';

import Cookie from 'vue-cookies';


const app = createApp(App);
app.use(pinia);
app.use(Ele);

app.config.productionTip = true;
app.config.globalProperties.$store = useSettingStore();

import common from '@/common/common.js'
app.config.globalProperties.$common = common;
app.config.globalProperties.$Cookie = Cookie;

app.use(AlloyFingerPlugin, {
    AlloyFinger
});

app.use(Viewer, {
    defaultOptions: {
        zIndex: 9999,
        navbar: false,
        title: false,
        filter: (Viewer) => (Viewer.className.indexOf("noviewer") < 0)
        /*toolbar: {
    //zoomIn: 4,
    //zoomOut: 4,
    //oneToOne: 4,
    //reset: 4,
    prev: 1,
    play: {
    show: 4,
    size: 'large',
    },
    next: 1,
    //rotateLeft: 4,
    //rotateRight: 4,
    //flipHorizontal: 4,
    //flipVertical: 4,
    } */
    }
});


const requireComponent = require.context(
    // 其组件目录的相对路径
    './components/base',
    // 是否查询其子目录
    false,
    // 匹配基础组件文件名的正则表达式
    /ff\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
    // 获取组件配置
    const componentConfig = requireComponent(fileName)

    // 获取和目录深度无关的文件名
    const componentName = fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '');
    // 全局注册组件
    app.component(
        componentName,
        // 如果这个组件选项是通过 `export default` 导出的，
        // 那么就会优先使用 `.default`，
        // 否则回退到使用模块的根。
        componentConfig.default || componentConfig
    )
});

app.directive('Clickoutside', vClickOutside)


NProgress.configure({ ease: 'ease', speed: 500 })

NProgress.start();
NProgress.done();

router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});

router.afterEach(() => {
    NProgress.done();
})

app.directive('title', {
    inserted: function (el) {
        document.title = el.dataset.title || '';
    }
})


app.use(router);
app.mount('#app')
