import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["innerHTML"];
export default {
  __name: 'ffcontent',
  props: {
    alias: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const FFprops = __props;
    const {
      proxy
    } = getCurrentInstance();
    const FFdata = reactive({
      infodata: {},
      isLoad: false
    });
    onMounted(() => {
      if (FFprops.alias) {
        proxy.$common.fetch({
          'act': 'common_infocon',
          'exts': 'alias=' + FFprops.alias,
          'msg': ''
        }, res => {
          if (proxy.$common.basic.isObjExist(res.infodata)) {
            FFdata.infodata = res.infodata;
          } else {
            FFdata.infodata.content = FFprops.alias;
          }
          FFdata.isLoad = true;
        });
      }
    });
    return (_ctx, _cache) => {
      return _unref(FFdata).isLoad ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "editor",
        innerHTML: _ctx.$common.langInfo(_unref(FFdata).infodata.content, _unref(FFdata).infodata.content_en).replace('@@', '©')
      }, null, 8, _hoisted_1)) : _createCommentVNode("", true);
    };
  }
};