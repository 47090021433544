import { createVNode as _createVNode, resolveComponent as _resolveComponent, unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["src"];
import Cheader from '@/components/Cheader.vue';
import Cfooter from '@/components/Cfooter.vue';
export default {
  __name: 'App',
  setup(__props) {
    const isShowVideo = ref(false);
    const videoUrl = ref('');
    onMounted(() => {
      let aprevent = (e, href) => {
        let hreflast = href ? href.substring(href.length - 1) : '';
        if (hreflast == "#") {
          if (e.preventDefault) {
            e.preventDefault();
          } else {
            e.returnValue = false;
          }
          //return false;
        } else {
          let extname = href.substring(href.lastIndexOf('.')).toLowerCase();
          if (extname == ".mp4") {
            isShowVideo.value = true;
            videoUrl.value = href;
            if (e.preventDefault) {
              e.preventDefault();
            } else {
              e.returnValue = false;
            }
          }
        }
      };
      window.addEventListener('click', e => {
        if (e.target.tagName) {
          if (e.target.tagName === 'A') {
            aprevent(e, e.target.href);
          } else {
            if (e.target.parentNode) {
              if (e.target.parentNode.tagName === 'A') {
                aprevent(e, e.target.parentNode.href);
              } else {
                if (e.target.parentNode.parentNode) {
                  if (e.target.parentNode.parentNode.tagName === 'A') {
                    aprevent(e, e.target.parentNode.parentNode.href);
                  }
                }
              }
            }
          }
        }
      });
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(Cheader), _createVNode(_component_router_view), _createVNode(Cfooter), _unref(isShowVideo) && _unref(videoUrl) ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        modelValue: _unref(isShowVideo),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _isRef(isShowVideo) ? isShowVideo.value = $event : null),
        class: "mydialog"
      }, {
        default: _withCtx(() => [_createElementVNode("video", {
          src: _unref(videoUrl),
          controls: "",
          autoplay: "",
          controlslist: "nodownload",
          onContextmenu: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
        }, null, 40, _hoisted_1)]),
        _: 1
      }, 8, ["modelValue"])) : _createCommentVNode("", true)], 64);
    };
  }
};